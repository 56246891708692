import React from "react";
import logo from './LOGOCOOPIAUTO.png'

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll row-cu" href="#page-top">
            <img className="logo" src={logo} alt="" srcset="" />
            <h1>COOPIAUTO</h1>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#features" className="page-scroll">
                Sobre
              </a>
            </li> */}
            <li>
              <a href="#about" className="page-scroll">
                Endereço
              </a>
            </li>
            <li>
              <a href="#sizzz" className="page-scroll">
                Serviços 
              </a>
            </li>
         
            <li>
              <a                   href="https://api.whatsapp.com/send?phone=5592992382932&text=VIM%20PELO%20SITE%20E%20gostaria%20de%20SABER%20MAIS%20SOBRE%20os%20serviços"
 className="page-scroll">
                Entrar em Contato
              </a>
            </li>
           
          </ul>
        </div>
      </div>
    </nav>
  );
};
