import React from "react";
import fachada from "./47095040-6f5f-456a-a74b-350366c4fc6e.jpg";
import "./about.css"

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">


      
          <div className="col-xs-12 col-md-6 ab-img ">
       
            <img  src={fachada} alt="" />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="about-text">
            <h2>Onde estamos?</h2>
          <p className="paragraph">
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              <h3>Porque nos escolher?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
