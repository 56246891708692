import React from "react";
import "./Header.css"; // Arquivo de estilos adicionais

export const Header = (props) => {
  return (
    <header id="header" className="header-section">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row-a">
              {/* Texto */}
              <div className="col-12 col-md-6 intro-text text-center text-md-start d-flex flex-column justify-content-center">
                <h1 className="header-title">
                  {props.data ? props.data.title : "Loading"}
                  <span className="highlight"></span>
                </h1>
                <p className="header-paragraph">
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <a
                  href="https://api.whatsapp.com/send?phone=5592992382932&text=VIM%20PELO%20SITE%20E%20gostaria%20de%20SABER%20MAIS%20SOBRE%20os%20serviços"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Entrar em Contato
                </a>
              </div>

              {/* Imagem */}
              <div className="col-12 col-md-6 text-center d-flex justify-content-center align-items-center">
                <img
                  src="https://www.macariservicos.com.br/uploads/5132.jpg"
                  alt="Imagem"
                  className="img-fluid header-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
