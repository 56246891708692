import React from 'react';
import './services.css'; // Certifique-se de criar este arquivo para estilizar os componentes

const ServicesComponent = () => {
  const services = [
    {
      id: 1,
      image: 'https://www.coopiauto.com.br/blackfriday/IMGS/2.jpg',
      title: 'PACOTE PRIMEIRA HABILITAÇÃO (A) MOTO',
      price: 'R$ 1300,00',
      vista: 'R$ 1200,00'
    },
    {
      id: 2,
      image: 'https://www.coopiauto.com.br/blackfriday/IMGS/3.jpg',
      title: 'PACOTE PRIMEIRA HABILITAÇÃO (B) CARRO',
      price: 'R$ 1750,00',
      vista: 'R$ 1650,00'
    },
    {
      id: 3,
      image: 'https://www.coopiauto.com.br/blackfriday/IMGS/6.jpg',
      title: 'PACOTE SIMULTÂNEO A/B',
      price: 'R$ 2300,00',
      vista: 'R$ 2150,00'
    },
    {
      id: 4,
      image: 'https://www.coopiauto.com.br/blackfriday/IMGS/4.jpg',
      title: 'INCLUSÃO DE CATEGORIA A',
      price: 'R$ 1000,00',
      vista: 'R$ 950,00'
    },
  ];

  const generateWhatsAppLink = (serviceTitle) => {
    const phoneNumber = "5592992382932"; // Número de telefone com DDD e código do país
    const message = `VIM%20PELO%20SITE%20E%20gostaria%20de%20SABER%20MAIS%20SOBRE%20o%20serviço%20${encodeURIComponent(serviceTitle)}`;
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
  };

  return (
    <div className='sizzz' id="sizzz">
      <h1 className='tit'>Nossos Serviços</h1>
      <div className="services-container">
        {services.map(service => (
          <div key={service.id} className="card">
            <img src={service.image} alt={service.title} className="card-image" />
            <h2 className="card-title">{service.title}</h2>
            <p className="card-price">{service.price}</p>
            <p>A vista com desconto : <span className='card-price'>{service.vista}</span></p>
            <a href={generateWhatsAppLink(service.title)} target="_blank" rel="noopener noreferrer">
              <button className="card-button">Comprar Agora</button>
            </a>
            <p className='cu'>( Fora taxas detran/Exames Médicos )</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesComponent;
