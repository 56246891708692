import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import ServicesComponent from "./components/services/services";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const sectionsRef = useRef([]);

  useEffect(() => {
    setLandingPageData(JsonData);

    // Configurar animações com GSAP
    sectionsRef.current.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%", // Inicia quando o topo da seção atinge 80% da viewport
            end: "bottom 20%", // Termina quando o final da seção atinge 20% da viewport
            toggleActions: "play none none none", // Dispara a animação apenas uma vez
          },
        }
      );
    });
  }, []);

  return (
    <div>
      <Navigation />
      <div ref={(el) => (sectionsRef.current[0] = el)}>
        <Header data={landingPageData.Header} />
      </div>
      <div ref={(el) => (sectionsRef.current[1] = el)}>
        <About data={landingPageData.About} />
      </div>
      <div ref={(el) => (sectionsRef.current[2] = el)}>
        <ServicesComponent />
      </div>
      {/* Adicione outras seções aqui */}
    </div>
  );
};

export default App;
